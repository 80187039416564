<template>
	<b-card>
		<!-- form -->
		<b-form>
			<b-row>
				<!-- old password -->
				<b-col md="6">
					<b-form-group :label="$t('Old Password')" label-for="account-old-password">
						<b-input-group class="input-group-merge">
							<b-form-input id="account-old-password" v-model="old_password" :placeholder="$t('Old Password')" :type="passwordFieldTypeOld" name="old-password" />
							<b-input-group-append is-text>
								<feather-icon :icon="passwordToggleIconOld" class="cursor-pointer" @click="togglePasswordOld" />
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
				</b-col>
				<!--/ old password -->
			</b-row>
			<b-row>
				<!-- new password -->
				<b-col md="6">
					<b-form-group :label="$t('New Password')" label-for="account-new-password">
						<b-input-group class="input-group-merge">
							<b-form-input id="account-new-password" v-model="new_password" :placeholder="$t('New Password')" :type="passwordFieldTypeNew" name="new-password" />
							<b-input-group-append is-text>
								<feather-icon :icon="passwordToggleIconNew" class="cursor-pointer" @click="togglePasswordNew" />
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
				</b-col>
				<!--/ new password -->

				<!-- retype password -->
				<b-col md="6">
					<b-form-group :label="$t('Retype New Password')" label-for="account-retype-new-password">
						<b-input-group class="input-group-merge">
							<b-form-input id="account-retype-new-password" v-model="retype_password" :placeholder="$t('New Password')" :type="passwordFieldTypeRetype" name="retype-password" />
							<b-input-group-append is-text>
								<feather-icon :icon="passwordToggleIconRetype" class="cursor-pointer" @click="togglePasswordRetype" />
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
				</b-col>
				<!--/ retype password -->

				<!-- buttons -->
				<b-col cols="12">
					<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mt-1 mr-1" variant="primary" @click="submit">
						{{ $t("Submit") }}
					</b-button>
					<b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" class="mt-1" type="reset" variant="outline-secondary" @click="reset">
						{{ $t("Reset") }}
					</b-button>
				</b-col>
				<!--/ buttons -->
			</b-row>
		</b-form>
	</b-card>
</template>

<script>
	import Ripple from "vue-ripple-directive";
	import { showToast } from "@/libs/utils/showToast";
	import store from "@/store";
	import { logout } from "@/libs/utils/logout";

	export default {
		directives: {
			Ripple,
		},
		data() {
			return {
				old_password: "",
				new_password: "",
				retype_password: "",
				passwordFieldTypeOld: "password",
				passwordFieldTypeNew: "password",
				passwordFieldTypeRetype: "password",
			};
		},
		computed: {
			passwordToggleIconOld() {
				return this.passwordFieldTypeOld === "password" ? "EyeIcon" : "EyeOffIcon";
			},
			passwordToggleIconNew() {
				return this.passwordFieldTypeNew === "password" ? "EyeIcon" : "EyeOffIcon";
			},
			passwordToggleIconRetype() {
				return this.passwordFieldTypeRetype === "password" ? "EyeIcon" : "EyeOffIcon";
			},
		},
		methods: {
			togglePasswordOld() {
				this.passwordFieldTypeOld = this.passwordFieldTypeOld === "password" ? "text" : "password";
			},
			togglePasswordNew() {
				this.passwordFieldTypeNew = this.passwordFieldTypeNew === "password" ? "text" : "password";
			},
			togglePasswordRetype() {
				this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === "password" ? "text" : "password";
			},
			submit() {
				if (this.old_password.length < 6) {
					showToast(null, "错误", "请重新确认旧密码是否正确");
				} else if (this.new_password.length < 6) {
					showToast(null, "错误", "新密码长度应不少于6位");
				} else if (this.new_password !== this.retype_password) {
					showToast(null, "错误", "两次输入的新密码不相同");
				} else {
					const payload = {
						old_password: this.old_password,
						new_password: this.new_password,
					};
					store
						.dispatch("users/changePassword", payload)
						.then((response) => {
							showToast(null, "成功", "密码修改成功，3秒后请重新登陆");
							setTimeout(() => {
								logout();
							}, 3000);
						})
						.catch((error) => {
							showToast(error);
						});
				}
			},
			reset() {
				this.old_password = "";
				this.new_password = "";
				this.retype_password = "";
			},
		},
	};
</script>
